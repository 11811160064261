<template>
  <b-card>
    <b-list-group>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Adı Soyadı
        </div>
        <div>{{ itemData.name }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          E-Posta
        </div>
        <div>{{ itemData.email }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Telefon
        </div>
        <div>{{ itemData.phone }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Başvuru Metni
        </div>
        <div>{{ itemData.content? itemData.content : '-' }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          KVKK Onayı
        </div>
        <div>{{ itemData.kvkk? 'Var' : 'Yok' }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Özgeçmiş Dosya Eki
        </div>
        <div class="mt-1">
          <b-button
            variant="success"
            size="sm"
            :href="baseURL + '/media/hr_forms/' + itemData.filename"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" />
            Dosya İndir
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ViewPage',
  components: {
    BCard, BListGroup, BListGroupItem, BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['hrForms/dataItem']
    },
  },
  created() {
    this.getDataItem()
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('hrForms/getDataItem', this.$route.params.id)
    },
  },
}
</script>
